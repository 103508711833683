import React from 'react'
import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import styles from './CookiesBanner.module.scss'
import USBButton from '@usb-shield/react-button'
import USBLink from '@usb-shield/react-link'
import { useCookies } from 'react-cookie'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  CookiesData,
  CTAData,
} from '@/modules/cookie-settings/cookieSettings.model'
import { setExpirationDays } from '@/utils/cookies/cookies'
import { v4 as uuidv4 } from 'uuid'
import { showSuccessToast } from '@/utils/toast/toast'
import { SETTINGS_SAVED_MSG } from '@/modules/cookie-settings/CookieSettingsPage';
    
const sixColsOption = {
  span: null,
  spans: {
    xlarge: 4,
    large: 5,
    medium: 4,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const leftBlock: any = {
  span: null,
  spans: {
    xlarge: 9,
    large: 10,
    medium: 4,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const CookiesBanner = (cookiesData: CookiesData) => {
  const apiCookieData = cookiesData
  const [cookies, setCookie, removeCookie] = useCookies()
  const [showBanner, setShowBanner] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if(!cookies['acceptCookies']) {
      setShowBanner(true)
    }
  }, [])
  
  const onAcceptCookies: any = () => {
    if (!cookies.acceptCookies || (cookies.acceptCookies && cookies.acceptCookies != ['Performance/Analytics Cookies'])) {
      let cookiesToBeAccepted: string[]  = []
      apiCookieData.cookieCheckBoxes.forEach(cookieCheckBox => {
        cookiesToBeAccepted.push(cookieCheckBox.label)
      });

      setCookie(
        'acceptCookies',
        cookiesToBeAccepted,
        { path: '/', expires: setExpirationDays(90) }
      )
      showSuccessToast(SETTINGS_SAVED_MSG)
      hideBanner()
    } 
  }

  const onRejectCookies: any = () => {
    removeCookie('acceptCookies');
    
    setCookie(
      'acceptCookies',
      ['Strictly Necessary Cookies'],
      { path: '/', expires: setExpirationDays(90) }
    )
    
    showSuccessToast(SETTINGS_SAVED_MSG)
    hideBanner()
  }

  const hideBanner = () => {
    setShowBanner(false)
  }

  const cookiePageCheck = router.asPath.split('/')[1] === 'cookie-settings'
  const renderRightSection = apiCookieData?.cookieCTAs?.map((res: CTAData) => {
    if (res?.ctalink === '') {
      const dataTestIdVal: string = res?.ctatext === 'Accept Cookies' ? 'acceptCookiesBtn' : 'rejectCookiesBtn'
      const clickHandler: string = res?.ctatext === 'Accept Cookies' ? onAcceptCookies : onRejectCookies
      return (
        <USBButton
          key={uuidv4()}
          variant='primary'
          size='default'
          addClasses={styles.cookiesButton}
          dataTestId={dataTestIdVal}
          handleClick={clickHandler}
        >
          {res?.ctatext}
        </USBButton>
      )
    } else {
      return (
        <USBLink
          key={uuidv4()}
          linkType='link'
          href={res?.ctalink}
          dataTestId="cookieSettingLnk"
        >
          {res?.ctatext}
        </USBLink>
      )
    }
  })

  if (process.env.SITE_BRAND.toLowerCase() === 'elavon') {
    sixColsOption.spans.xlarge = 6
    sixColsOption.spans.large = 6
    sixColsOption.align = 'end'
    sixColsOption.justify = 'end'
    
    leftBlock.spans.xlarge = 10
    leftBlock.spans.large = 10
    leftBlock.offset.xlarge = 0
    leftBlock.offset.large = 0
  }
  return (
    <>
      {showBanner &&
        !cookies.acceptCookies &&
        !cookiePageCheck && (
          <USBGrid
            gridGap="zero"
            addClasses={styles.gridWrapper}
            alignItems="stretch"
            columnCount="16"
            justifyContent="stretch"
            display="grid"
            padding="normal"
          >
            <USBColumn layoutOpts={leftBlock} addClasses={styles.cookiesBlock}>
              <div
                className={styles.marginLayout}
                dangerouslySetInnerHTML={{
                  __html: apiCookieData?.cookieBannerDisclaimerText,
                }}
              ></div>
            </USBColumn>
            <USBColumn layoutOpts={sixColsOption} addClasses={styles.cookiesBlock}>
              <div className={`${styles.marginLayout} `}>{renderRightSection}</div>
            </USBColumn>
          </USBGrid>
        )}
    </>
  )
}

export default CookiesBanner
